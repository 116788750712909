#wave {
  position: absolute;
  left: 0;
  right: 0;
}

.notification-top-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  line-height: 40px;
  width: 100%;
  background: #0068ef;
  text-align: center;
  color: #ffffff;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 14px;
  z-index: 10;
}
.notification-top-bar div {
  padding: 0;
  margin: 0;
}
.notification-top-bar a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.signupIframe {
  height: 320px;
}

#prevIssues {
  text-decoration: none;
  color: black;
}

.made-by {
  font-family: "Helvetica Neue", sans-serif;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 100;
  border-top-left-radius: 5px;
  padding: 12px;
  border-top: 1px solid #efefef;
  border-left: 1px solid #efefef;
  background: #fff;
  color: #6f6f6f;
  text-decoration: none;
}
.made-by:hover {
  background: #fff;
  color: rgb(0, 104, 239); /* change to your main brand color */
}
.made-by img {
  border-radius: 100%;
  width: 22px;
  vertical-align: middle;
}
.made-by p {
  margin: 0;
  vertical-align: middle;
  display: inline;
  margin-left: 7px;
  font-weight: 500;
  font-size: 14px;
}
:root {
  --border-size: 0.125rem;
  --duration: 250ms;
  --ease: cubic-bezier(0.215, 0.61, 0.355, 1);
  --color-primary: white;
  --color-secondary: pink;
  --color-tertiary: dodgerblue;
  --shadow: rgba(0, 0, 0, 0.1);
  --space: 1rem;
}

.rainbow-text {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 2rem;
  font-family: var(--font-family);
  background: linear-gradient(
    to right,
    #ef5350,
    #f48fb1,
    #7e57c2,
    #2196f3,
    #26c6da,
    #43a047,
    #eeff41,
    #f9a825,
    #ff5722
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.multi-button {
  display: flex;
  width: 100%;
  box-shadow: var(--shadow) 4px 4px;
}

.multi-button button {
  flex-grow: 1;
  cursor: pointer;
  position: relative;
  padding: calc(var(--space) / 1.125) var(--space) var(--space);
  border: var(--border-size) solid black;
  color: var(--color-secondary);
  background-color: var(--color-primary);
  font-size: 1.5rem;
  font-family: var(--font-family);
  /* text-shadow: var(--shadow) 2px 2px; */
  transition: flex-grow var(--duration) var(--ease);
}

.multi-button button + button {
  border-left: var(--border-size) solid black;
  margin-left: calc(var(--border-size) * -1);
}

.multi-button button:hover,
.multi-button button:focus {
  flex-grow: 2;
  color: white;
  outline: none;
  text-shadow: none;
  background-color: var(--color-secondary);
}

.multi-button button:focus {
  outline: var(--border-size) dashed var(--color-primary);
  outline-offset: calc(var(--border-size) * -3);
}

.multi-button:hover button:focus:not(:hover) {
  flex-grow: 1;
  color: var(--color-secondary);
  background-color: var(--color-primary);
  outline-color: var(--color-tertiary);
}

.multi-button button:active {
  transform: translateY(var(--border-size));
}
/* Book container */
.book-container {
  position: relative; /* Position the container relative to the parent element */
  width: 250px; 
  height: 400px; 
  margin: 0 auto; /* Center the container horizontally */
  perspective: 800px; /* Add a 3D perspective to the container */
  transform-style: preserve-3d; /* Enable 3D transforms on child elements */
}

/* Book cover */
.book-cover {
  position: absolute; /* Position the book cover absolute to the container */
  top: 0; /* Position the book cover at the top of the container */
  left: 0; /* Position the book cover at the left of the container */
  width: 100%; /* Set the width of the book cover to fill the container */
  height: 100%; /* Set the height of the book cover to fill the container */
  transform-origin: left center; /* Set the origin of the 3D transform to the left center of the book cover */
  transition: transform 0.5s ease-in-out; /* Add a transition effect to the transform property */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3); /* Add a book-like depth effect */
  border-radius: 20px; /* Add rounded corners */
}

/* Animation on hover */
.book-container:hover .book-cover {
  transform: rotateY(5deg) rotateX(5deg); /* Rotate the book cover on the Y-axis and X-axis */
}

.buy-now-button {
  color: #0067EF;
  text-decoration: none;
  font-weight: semi-bold;
}